import { Save } from "@carbon/icons-react";
import { ILang } from "../../../Interfaces/ILang.type";
import { Grouper } from "../../UI/Grouper/Grouper.view";
import { TextArea } from "../../UI/InputFields/TextInput/TextArea.view";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { SEO_LANGS } from "../Static/SEOLangs.static";
import { ImageInput } from "../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { FC, useMemo } from "react";
import { useProducts } from "../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { isEqualWith } from "lodash";
import { PLACEHOLDERS } from "../Static/PLACEHOLDERS.static";
import { FloatingError } from "xa-error-with-lang";
import { IConfigGroups } from "../Interfaces/IConfigGroups.type";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { CheckboxInput } from "../../UI/InputFields/Checkbox/Checkbox.view";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { IUseFormResult } from "../../UseForm/UseForm.provider";
import { BG_SIZE_OPTIONS } from "../../UI/InputGroups/BackgroundImage/BGSizeOptions.static";
import { BG_POSITION_OPTIONS } from "../../UI/InputGroups/BackgroundImage/BGPositionOptions.static";
import { GroupContextProvider } from "../../UI/Grouper/Group.provider";
import { QR_CART_MODE_OPTIONS } from "../../../Static/QRCartMode.static";
import { BG_ATTACHMENT_OPTIONS } from "../../UI/InputGroups/BackgroundImage/BGAttachmentOptions.static";
import { FONT_OPTIONS, IFontOption } from "sitebuilder-common";
import { ConfigModel, AnalyticsModel } from "sitebuilder-common";
import { IError, ILoading, ISelectOption, ISetState, Regexes } from "xa-generics";
import ColorInput from "../../UI/InputFields/ColorInput/ColorInput.view";
import Loading from "../../UI/Loading/Loading.view";
import Button from "../../UI/Button/Button.view";

interface IConfigViewProps {
    conversionForm: IUseFormResult<AnalyticsModel, true>;
    saveAnalytics: (data: AnalyticsModel) => void;
    form: IUseFormResult<ConfigModel, true>;
    currentAnalytics: AnalyticsModel | null;
    setError: ISetState<IError>;
    isConversionSaved: boolean;
    showCurrent: () => void;
    saveConfig: () => void;
    isAltered: boolean;
    loading: ILoading;
    error: IError;
}

export const ConfigView: FC<IConfigViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();
    const { categories } = useProducts();
    const { editor, handleChange, handleSubmit, fieldErrors } = props.form;

    const productOptions = useMemo(() => {
        const products: { id: string; name: string }[] = [];
        for (const category of categories) {
            for (const product of category.products) {
                for (const subproduct of product.subproducts) {
                    let name = `${category.name} > ${product.name}`;
                    if (subproduct.name) name += ` | ${subproduct.name}`;
                    const id = product.id;
                    products.push({ id, name });
                }
            }
        }
        return products.sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();
            const aIncludes = aName.includes("elvitel") || aName.includes("rendszer");
            if (aIncludes) return -1;
            else if (bName.includes("elvitel") || bName.includes("rendszer")) return 1;
            return 0;
        });
    }, [categories]);

    return (
        <form className="editor__content config" onSubmit={handleSubmit(props.saveConfig)}>
            <FloatingError error={props.error} resetError={() => props.setError(null)} />
            <Loading loading={props.loading} isAbsolute useSpacing />
            {restaurant.domain && (
                <div className="config__domain">
                    <strong>{t<ILang>("rest_domain")}:</strong>
                    <br />
                    <small>
                        <a
                            target={"_blank"}
                            className={"link"}
                            rel={"noreferrer noopener"}
                            href={`https://${restaurant.domain}`}
                        >
                            {restaurant.domain || "-"}
                        </a>
                    </small>
                </div>
            )}
            <GroupContextProvider<IConfigGroups>>
                <Grouper<IConfigGroups> groupName={"theme"} title={"theme_config"}>
                    <CheckboxInput<ConfigModel>
                        id={"is_dark_theme"}
                        value={editor.is_dark_theme}
                        onChange={handleChange}
                        errors={fieldErrors}
                    />

                    <CheckboxInput<ConfigModel>
                        id={"is_rounded_btn"}
                        value={editor.is_rounded_btn}
                        onChange={handleChange}
                        errors={fieldErrors}
                    />

                    <ColorInput<ConfigModel>
                        isClearable
                        errors={fieldErrors}
                        onChange={handleChange}
                        id={"main_btn_bg_color"}
                        value={editor.main_btn_bg_color}
                    />

                    <ColorInput<ConfigModel>
                        isClearable
                        errors={fieldErrors}
                        onChange={handleChange}
                        id={"secondary_btn_bg_color"}
                        value={editor.secondary_btn_bg_color}
                    />

                    <ColorInput<ConfigModel>
                        isClearable
                        errors={fieldErrors}
                        onChange={handleChange}
                        id={"main_btn_color"}
                        value={editor.main_btn_color}
                    />

                    <ColorInput<ConfigModel>
                        isClearable
                        errors={fieldErrors}
                        onChange={handleChange}
                        id={"secondary_btn_color"}
                        value={editor.secondary_btn_color}
                    />

                    <ColorInput<ConfigModel>
                        isClearable
                        errors={fieldErrors}
                        onChange={handleChange}
                        id={"body_bg_color"}
                        value={editor.body_bg_color}
                    />

                    <ColorInput<ConfigModel>
                        isClearable
                        errors={fieldErrors}
                        onChange={handleChange}
                        id={"body_color"}
                        value={editor.body_color}
                    />
                </Grouper>

                <Grouper<IConfigGroups> groupName={"settings"} title={"main_config"}>
                    <CheckboxInput<ConfigModel>
                        id={"suggest_in_cart"}
                        value={editor.suggest_in_cart}
                        onChange={handleChange}
                        errors={fieldErrors}
                    />

                    <CheckboxInput<ConfigModel>
                        id={"suggest_on_add"}
                        value={editor.suggest_on_add}
                        onChange={handleChange}
                        errors={fieldErrors}
                    />
                    <SelectInput
                        isClearable
                        id={"qr_cart_mode"}
                        errors={fieldErrors}
                        useTranslationOnName
                        onChange={handleChange}
                        value={editor.qr_cart_mode}
                        options={QR_CART_MODE_OPTIONS}
                    />

                    <SelectInput
                        isClearable
                        options={productOptions}
                        id={"takeaway_product_id"}
                        onChange={props.form.handleChange}
                        labelText={"takeAwayProductId"}
                        value={props.form.editor.takeaway_product_id}
                    />
                    <SelectInput
                        isClearable
                        id={"systemfee_item"}
                        options={productOptions}
                        onChange={props.form.handleChange}
                        value={props.form.editor.systemfee_item}
                    />
                    {/* <TextInput
                        isNumeric
                        errors={fieldErrors}
                        onChange={handleChange}
                        id={"takeaway_product_id"}
                        labelText={"takeAwayProductId"}
                        value={editor.takeaway_product_id}
                    /> */}
                    <ImageInput<ConfigModel>
                        id={"favicon_image_id"}
                        onChange={handleChange}
                        value={editor.favicon_image_id}
                    />

                    <SelectInput<ConfigModel, IFontOption>
                        isClearable
                        options={FONT_OPTIONS}
                        id={"font_family_cdn"}
                        value={editor.font_family_cdn}
                        onChange={handleChange}
                        formatOptionLabel={(option) => (
                            <div className={`font-option ${option.class}`}>
                                {option.name}{" "}
                                <span className="example-text">
                                    ({t<ILang>("example_text_font")})
                                </span>
                            </div>
                        )}
                    />
                    <ImageInput<ConfigModel>
                        id={"body_bg_image_id"}
                        onChange={handleChange}
                        value={editor.body_bg_image_id}
                    />

                    <SelectInput<ConfigModel, ISelectOption>
                        options={BG_SIZE_OPTIONS}
                        isClearable
                        useTranslationOnName
                        id={"body_bg_image_size"}
                        value={editor.body_bg_image_size}
                        onChange={handleChange}
                    />

                    <SelectInput<ConfigModel, ISelectOption>
                        options={BG_POSITION_OPTIONS}
                        isClearable
                        useTranslationOnName
                        id={"body_bg_image_position"}
                        value={editor.body_bg_image_position}
                        onChange={handleChange}
                    />

                    <SelectInput<ConfigModel, ISelectOption>
                        isClearable
                        options={BG_ATTACHMENT_OPTIONS}
                        useTranslationOnName
                        id={"body_bg_image_attach"}
                        labelText={"backgroundAttachment"}
                        value={editor.body_bg_image_attach}
                        onChange={handleChange}
                    />

                    <CheckboxInput<ConfigModel>
                        id={"body_bg_repeat"}
                        value={editor.body_bg_repeat}
                        onChange={handleChange}
                        errors={fieldErrors}
                    />
                </Grouper>

                <Grouper<IConfigGroups> groupName={"seo"} title={"seo_config"}>
                    <h6 className="config__description">
                        {t<ILang>("warning")} {t<ILang>("config_seo_description")}
                    </h6>

                    <ImageInput<ConfigModel>
                        id={"og_image_id"}
                        onChange={handleChange}
                        value={editor.og_image_id}
                    />

                    <TextInput<ConfigModel>
                        id={"og_title"}
                        value={editor.og_title}
                        onChange={handleChange}
                    />

                    <TextInput<ConfigModel>
                        id={"og_description"}
                        value={editor.og_description}
                        onChange={handleChange}
                    />

                    <TextInput<ConfigModel>
                        id={"og_image_alt"}
                        value={editor.og_image_alt}
                        onChange={handleChange}
                        placeholder={t<ILang>("og_image_alt_placeholder")}
                    />

                    <SelectInput<ConfigModel, ISelectOption>
                        id={"og_locale"}
                        options={SEO_LANGS}
                        value={editor.og_locale}
                        onChange={handleChange}
                    />

                    <SelectInput<ConfigModel, ISelectOption>
                        id={"og_locale_alternate"}
                        options={SEO_LANGS}
                        value={editor.og_locale_alternate}
                        onChange={handleChange}
                    />
                </Grouper>

                <Button
                    useContainer
                    align={"right"}
                    size={"FIELD"}
                    type={"submit"}
                    Icon={Save}
                    disabled={!props.isAltered}
                >
                    {t<ILang>("save")}
                </Button>

                <Grouper<IConfigGroups>
                    groupName={"conversion_api"}
                    title={"conversion_api"}
                    onOpen={() => {
                        if (!props.currentAnalytics) props.showCurrent();
                    }}
                >
                    <div className="config__sub-title --first">Google</div>
                    <TextInput
                        autoComplete={false}
                        id={"ga_ad_purchase_conversion_id"}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        placeholder={"AW-16461252854/0zXQCOSNj7QZFKGMb6k1"}
                        value={props.conversionForm.editor.ga_ad_purchase_conversion_id}
                    />

                    <TextArea
                        id={"ga_head_script"}
                        placeholder={PLACEHOLDERS.ga_head}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        value={props.conversionForm.editor.ga_head_script}
                    />

                    <TextArea
                        id={"gtag_body_script"}
                        placeholder={PLACEHOLDERS.gtag_body}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        value={props.conversionForm.editor.gtag_body_script}
                    />

                    <div className="config__sub-title --conversion">{t<ILang>("conversion")}</div>
                    <TextInput
                        id={"ga_secret"}
                        autoComplete={false}
                        disabled={!props.currentAnalytics}
                        placeholder={"ei6uOdvFR6Kud1JZ7s_REA"}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        value={props.conversionForm.editor.ga_secret}
                    />
                    <TextInput
                        autoComplete={false}
                        id={"ga_measurement_id"}
                        placeholder={"G-SDYMA7SHTP"}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        value={props.conversionForm.editor.ga_measurement_id}
                    />

                    <hr className={"hr"} />

                    <div className="config__sub-title">Facebook</div>
                    <TextArea
                        id={"fb_head_script"}
                        placeholder={PLACEHOLDERS.fb_head}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        value={props.conversionForm.editor.fb_head_script}
                    />

                    <div className="config__sub-title --conversion">{t<ILang>("conversion")}</div>
                    <TextInput
                        autoComplete={false}
                        id={"fb_access_token"}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        placeholder={PLACEHOLDERS.fb_token}
                        onChange={(field, value) => {
                            if (Regexes.Url.test(value)) {
                                const regex =
                                    /\/v\d+\.\d+\/([^/\\]+)\/events\?access_token=([^&]+)/;
                                const match = value.match(regex);

                                if (match) {
                                    const pixelId = match[1];
                                    const tokenValue = match[2];
                                    if (pixelId && tokenValue) {
                                        props.conversionForm.bulkChange([
                                            { field: "fb_pixel_id", value: pixelId },
                                            { field: "fb_access_token", value: tokenValue }
                                        ]);
                                    }
                                } else {
                                    props.conversionForm.handleChange(field, value);
                                }
                            } else {
                                props.conversionForm.handleChange(field, value);
                            }
                        }}
                        value={props.conversionForm.editor.fb_access_token}
                        description={
                            <div className={"fb_example"}>
                                {t<ILang>("fb_url_example")}:<br />
                                <small>
                                    <i>
                                        https://graph.facebook.com/v19.0/754948912342547/
                                        <br />
                                        <i className={"hidden"}>
                                            events?access_token=lkjboiwjoberiuj297bh4oi2b3h4iu23h49283ihjiu2o3h43bjklsjelbifjslkbjn2938b42oi3bj4234bö82j3
                                        </i>
                                    </i>
                                </small>
                                <br />
                                {t<ILang>("fb_token_example")}
                            </div>
                        }
                    />
                    <TextInput
                        id={"fb_pixel_id"}
                        autoComplete={false}
                        placeholder={PLACEHOLDERS.fb_pixel}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        value={props.conversionForm.editor.fb_pixel_id}
                        description={
                            <div className={"fb_example"}>
                                {t<ILang>("fb_pixel_example")}:<br />
                                <small>
                                    <i>873339587924831</i>
                                </small>
                            </div>
                        }
                    />
                    <hr className={"hr"} />

                    <div className="config__sub-title">Hotjar</div>
                    <TextArea
                        id={"hotjar_script"}
                        placeholder={PLACEHOLDERS.hotjar}
                        disabled={!props.currentAnalytics}
                        errors={props.conversionForm.fieldErrors}
                        onChange={props.conversionForm.handleChange}
                        value={props.conversionForm.editor.hotjar_script}
                    />

                    {props.isConversionSaved && (
                        <div className={"pixel-saved-text"}>
                            {t<ILang>("conversion_code_updated")}
                        </div>
                    )}
                    <Button
                        useContainer
                        align={"right"}
                        size={"FIELD"}
                        type={"submit"}
                        disabled={isEqualWith(
                            props.conversionForm.editor,
                            props.currentAnalytics,
                            (value, other) => {
                                if (!other) return false;
                                for (let key in value) {
                                    const originVal = value[key];
                                    const otherVal = other[key];

                                    if (originVal !== otherVal) {
                                        return false;
                                    }
                                }
                                return true;
                            }
                        )}
                        Icon={props.loading ? <InlineLoading isAlternate /> : Save}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            props.conversionForm.handleSubmit(props.saveAnalytics)(e);
                        }}
                    >
                        {t<ILang>("save_analytics")}
                    </Button>
                </Grouper>
            </GroupContextProvider>
        </form>
    );
};
